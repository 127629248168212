.m-additions {
	padding: 1.125rem 0.75rem;
	overflow: hidden;
	&-title {
		font-size: 1.25rem;
		font-weight: 700;
		margin-bottom: 1rem;
		transition: 0.25s;
	}
	&-item {
		height: auto;
		&__inner {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
		&__desc {
			display: flex;
			flex-direction: column;
			flex: 1;
		}
		.img {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 91%;
			border-radius: 16px 0px;
			overflow: hidden;
			margin-bottom: 0.25rem;
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.name {
			font-weight: 700;
			font-size: 0.875rem;
			margin-bottom: 0.25rem;
			flex: 1;
		}
		.weight {
			color: #bdbdbd;
			font-size: 0.875rem;
			margin-bottom: 0.375rem;
		}
		.bottom {
			margin-top: autos;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.price {
				font-weight: 700;
				padding-right: 0.25rem;
			}
			.plus-btn {
				cursor: pointer;
				width: 32px;
				height: 32px;
				border: none;
				outline: none;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgb(235, 87, 87);
				border-radius: 50%;
				border: 1px solid transparent;
				transition: 0.3s;
				& > * {
					flex-shrink: 0;
				}
				&:hover {
					background-color: transparent;
					border: 1px solid rgb(235, 87, 87);
				}
			}
		}
	}
	&-slider {
		position: relative;
		&::after {
			content: "";
			display: block;
			position: absolute;
			width: 0.75rem;
			left: 100%;
			height: 100%;
			top: 0;
			z-index: 2;
			background: linear-gradient(
				270deg,
				rgba(17, 17, 17, 1) 0%,
				rgba(17, 17, 17, 0.5) 48.23%,
				rgba(17, 17, 17, 0) 100%
			);
		}
		&::before {
			content: "";
			display: block;
			position: absolute;
			width: 0.75rem;
			right: 100%;
			height: 100%;
			top: 0;
			z-index: 2;
			background: linear-gradient(
				-270deg,
				rgba(17, 17, 17, 1) 0%,
				rgba(17, 17, 17, 0.5) 48.23%,
				rgba(17, 17, 17, 0) 100%
			);
		}
	}
	&-next,
	&-prev {
		display: none;
	}
	&-bottom {
		padding-top: 0.75rem;
		margin-top: 1.125rem;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		.item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 0.625rem;
			padding-bottom: 0.625rem;
			.quantity {
				height: 36px;
			}
		}
	}
	@include mq("md") {
		padding: 1.5rem 4.5rem;
		&-slider {
			&::after {
				width: 4.5rem;
			}
			&::before {
				width: 4.5rem;
			}
		}
		&-next,
		&-prev {
			display: block;
			cursor: pointer;
			transition: 0.25s;
			width: 46px;
			height: 46px;
			z-index: 5;
			display: flex;
			align-items: center;
			justify-content: center;
			filter: drop-shadow(0px 0px 4px rgb(95, 95, 95));
			&:hover {
				filter: drop-shadow(0px 0px 0px $black);
			}
			& > * {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
			&.swiper-button-disabled {
				pointer-events: none;
				opacity: 0;
			}
		}
		&-next {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(50%, -50%) scale(1);
			&.swiper-button-disabled {
				transform: translate(50%, -50%) scale(0.4);
			}
		}
		&-prev {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(-70%, -50%) scale(1);
			&.swiper-button-disabled {
				transform: translate(-70%, -50%) scale(0.4);
			}
		}
	}
}
