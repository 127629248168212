.m-subscription {
    &-center {
        display: none;
    }
    &-top {
        margin-bottom: 1.5rem;
        .text {
            color: rgba(189, 189, 189, 1);
        }
    }
    &-bottom {
        max-width: 420px;

        .subsc-btn {
            transition: 0.3s;
            svg {
                path {
                    transition: 0.3s;
                }
                rect {
                    transition: 0.3s;
                }
            }
            &:hover {
                transform: scale(1.3);
                svg {
                    path {
                        fill: goldenrod;
                    }
                    rect {
                        stroke: goldenrod;
                    }
                }
            }
            &:active {
                transform: scale(0.9);
            }
        }
    }
    @include mq('md') {
        justify-content: space-between;
        &-top {
            width: 33%;
            padding-right: 1rem;
        }
        &-center {
            display: block;
            width: 25%;
            .img {
                img {
                    margin: auto;
                }
            }
        }
        &-bottom {
            width: 33%;
            padding-left: 1rem;
        }
    }
    @include mq('lg') {
        &-center {
            display: block;
            width: 33%;
        }
    }
}
