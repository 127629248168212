.m-header {
    height: 100%;
    &-menu {
        position: absolute;
        left: -102%;
        top: 0;
        height: 100vh;
        width: 100%;
        padding: 6rem 0.75rem 3rem;
        background-color: $black;
        transition: 0.8s;
        opacity: 0.6;
        z-index: 113;
        &__inner {
            overflow: auto;
            height: 100%;
        }
        &.active {
            opacity: 1;
            left: 0;
        }
        &__list {
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            li {
                margin-bottom: 1.5rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            a {
                padding: 0.75rem;
                transition: 0.3s;
                background: transparent;
                &:hover {
                    background: $gold;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                }
            }
        }
        &__phone {
            text-align: center;
            margin-top: 1.5rem;
            a {
                padding: 0.75rem;
                transition: 0.3s;
                &:hover {
                    background: $gold;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
    &-socials__list {
        margin-top: 1.5rem;
        text-align: center;
        padding-top: 1.5rem;
        border-top: 1px solid rgba(225, 225, 225, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            margin-right: 1.5rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &-center {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-right {
        &__item {
            &.a-login {
                margin-right: 0.75rem;
            }
            a,
            button {
                width: 38px;
                height: 38px;
                position: relative;
                background: $gold;
                z-index: 0;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.625rem;
                outline: none;
                border: none;
                svg {
                    width: 100%;
                    height: auto;
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    margin: 1px; /* !importanté */
                    border-radius: inherit; /* !importanté */
                    background: $black;
                }
                .count {
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-radius: 0px 10px;
                    background: $gold;
                    color: white;
                    display: flex;
                    font-size: 0.75rem;
                    padding: 3px;
                    transform: translate(25%, -25%) rotate(0deg);
                    align-items: center;
                    justify-content: center;
                    min-width: 18px;

                    &.active {
                        animation: add-to-cart 1.25s ease;
                        @keyframes add-to-cart {
                            0% {
                                transform: translate(25%, -25%) rotate(0deg);
                            }
                            10% {
                                transform: translate(25%, -25%) rotate(20deg);
                            }
                            20% {
                                transform: translate(25%, -25%) rotate(-20deg);
                            }

                            30% {
                                transform: translate(25%, -25%) rotate(16deg);
                            }
                            40% {
                                transform: translate(25%, -25%) rotate(-16deg);
                            }
                            50% {
                                transform: translate(25%, -25%) rotate(12deg);
                            }
                            60% {
                                transform: translate(25%, -25%) rotate(-12deg);
                            }
                            70% {
                                transform: translate(25%, -25%) rotate(6deg);
                            }
                            80% {
                                transform: translate(25%, -25%) rotate(-6deg);
                            }
                            90% {
                                transform: translate(25%, -25%) rotate(4deg);
                            }
                            100% {
                                transform: translate(25%, -25%) rotate(0deg);
                            }
                        }
                    }
                }
            }
        }
    }
    @include mq('sm') {
        &-menu {
            width: 75%;
            font-size: 1.125rem;
            &__phone {
                font-size: 1.125rem;
            }
        }
        &-right {
            &__item {
                a,
                button {
                    width: 42px;
                    height: 42px;
                    padding: 0.75rem;
                }
            }
        }
    }
    @include mq('md') {
        &-menu {
            width: 55%;
            padding-top: 6.5rem;
            padding-right: 1.5rem;
            padding-left: calc(2% + 0.75rem);
            font-size: 1.25rem;
            &__list {
                text-align: start;
                padding-left: 0.5rem;
                li {
                    a {
                        padding-left: 0;
                    }
                }
            }
            &__phone {
                display: none;
            }
        }
        &-socials__list {
            justify-content: flex-start;
            padding-left: 0.5rem;
        }
        &-right {
            &__item {
                &.a-login {
                    margin-right: 1rem;
                }
            }
        }
    }
    @include mq('lg') {
        &-menu {
            width: 40%;
            padding-top: 7rem;
            padding-left: calc(3vw + 1rem);
            padding-right: 2rem;
            font-size: 1.375rem;
            &__list {
                padding-left: 0;
            }
        }
        &-socials__list {
            padding-left: 0;
        }
    }
    @include mq('xl') {
        &-menu {
            width: 30%;
            padding-top: 7.5rem;
            font-size: 1.5rem;
            padding-right: 3rem;
            padding-left: calc(6vw + 1rem);
            &__list {
                li {
                    margin-bottom: 1.75rem;
                }
            }
        }
        &-right {
            &__item {
                a,
                button {
                    width: 46px;
                    height: 46px;
                }
            }
        }
    }
    @include mq('xxl') {
        &-menu {
            padding-top: 8rem;
            //padding-left: calc(100vw - 1480px);
        }
    }
    @media only screen and (min-width: 1680px) {
        &-menu {
            padding-left: calc((100vw - 1480px) / 2 + 1rem);
        }
    }
    @media only screen and (min-width: 1921px) {
        &-menu {
            padding-left: 15rem;
        }
    }
}
