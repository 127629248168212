.banners-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    padding-right: -0.75rem;
    .item {
        width: 33.333%;
        padding: 0.75rem;
        & > * {
            position: relative;
            width: 100%;
            display: flex;
            padding-bottom: 55%;
            height: 0;
            border-radius: 14px;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                object-fit: cover;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .item {
            width: 50%;
        }
    }
    @media screen and (max-width: 560px) {
        .item {
            width: 100%;
        }
    }
}
button.add-banner {
    svg {
        padding: 0;
        width: 30%;
        height: auto;
        position: absolute;
        display: block;
        object-fit: cover;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    transition: 0.3s;
    background-color: rgba(141, 141, 141, 0.753);
    &:hover {
        background-color: rgba(255, 255, 255, 0.7);
    }
    &:active {
        transform: scale(0.96);
    }
}
