.products {
	&-list {
		border: 0.5px solid rgba(135, 135, 135, 0.2);
		box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.06);
		min-width: 540px;
		overflow-x: auto;
		&__item {
			display: flex;
			align-items: center;
			padding: 0.5rem 0.5rem;
			height: 54px;
			border-bottom: 0.5px solid rgba(135, 135, 135, 0.2);
			cursor: context-menu;
			&.item {
				transition: 0.3s;
				&:hover {
					background-color: rgba(135, 135, 135, 0.08);
				}
			}
			& > * {
				border-right: 0.5px solid rgba(135, 135, 135, 0.2);
				display: flex;
				align-items: center;
				height: 100%;
				padding: 0 0.5rem;
				&:first-child {
					flex: 1;
				}
				&:nth-child(2) {
					flex: 5;
				}
				&:nth-child(3) {
					flex: 3;
				}
				&:nth-child(4) {
					flex: 3;
				}
				&:nth-child(5) {
					flex: 2;
				}
				&:last-child {
					border-right: 0;
				}
			}
			.name {
				img {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					overflow: hidden;
					object-fit: cover;
					margin-right: 0.5rem;
				}
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}
}
