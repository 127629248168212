.m-login {
	&-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 1rem;
		position: relative;
		font-size: 1.125rem;
		font-weight: 700;
		padding-right: 0.5rem;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0.55rem;
			height: 1px;
			background-color: #9496a1;
			display: block;
			bottom: 0;
		}
	}
	&-content {
		overflow: auto;
		padding-bottom: 1.5rem;
		padding-top: 0.75rem;
		padding-right: 0.5rem;
		&::-webkit-scrollbar {
			width: 0.375em;
		}
		&::-webkit-scrollbar-track {
			background-color: rgba(135, 135, 135, 0.2);
		}
		&::-webkit-scrollbar-thumb {
			background: $gold;
			border-radius: 10px;
		}
	}
	&-tabs {
		ul {
			display: flex;
			align-items: center;
			height: 42px;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				display: block;
				width: 100%;
				height: 1px;
				background-color: #9496a1;
				bottom: 0;
				left: 0;
			}
			li {
				flex: 1;
				text-align: center;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				color: #9496a1;
				font-size: 1.25rem;
				transition: 0.3s;
				cursor: pointer;
				font-weight: 400;
				&::after {
					content: "";
					position: absolute;
					display: block;
					width: 100%;
					height: 2px;
					background-color: white;
					bottom: 0;
					left: 0;
					opacity: 0;
					transition: 0.3s;
				}
				&:hover {
					color: white;
				}
				&.active {
					color: white;
					font-weight: 700;
					&::after {
						opacity: 1;
					}
				}
			}
		}
	}
	&-forms {
		&__item {
			height: 0;
			overflow: hidden;
			opacity: 0;
			transition: 0.3s;
			&.active {
				height: auto;
				opacity: 1;
			}
		}
		&__bottom {
			margin-top: 1.25rem;
			a {
				color: #9496a1;
				transition: 0.3s;
				&:hover {
					color: white;
				}
			}
		}
		.a-btn {
			margin-top: 1.25rem;
		}
	}
	@include mq("md") {
		&-top {
			padding-bottom: 1.25rem;
			font-size: 1.25rem;
		}
		&-content {
			padding-top: 1rem;
		}
		&-tabs {
			ul {
				height: 48px;

				li {
					font-size: 1.375rem;
				}
			}
		}
		&-forms {
			.input {
				margin-top: 1.5rem;
			}
			&__bottom {
				margin-top: 1.375rem;
			}
			.a-btn {
				margin-top: 1.375rem;
			}
		}
	}
	@include mq("lg") {
		&-top {
			font-size: 1.375rem;
		}
		&-content {
			padding-top: 1.5rem;
		}
		&-tabs {
			ul {
				li {
					font-size: 1.5rem;
				}
			}
		}
		&-forms {
			.input {
				margin-top: 1.75rem;
			}
			.a-btn {
				margin-top: 1.5rem;
			}
		}
	}
}
