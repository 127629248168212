.m-cart {
	&-scroll {
		overflow: auto;
		height: 100%;
		&::-webkit-scrollbar {
			width: 0.375em;
		}
		&::-webkit-scrollbar-track {
			background-color: rgba(135, 135, 135, 0.2);
		}
		&::-webkit-scrollbar-thumb {
			background: $gold;
			border-radius: 10px;
		}
	}
	&-content {
		padding-top: 1.5rem;
		//padding-bottom: 2rem;
	}
	&-title {
		font-size: 1.25rem;
		text-transform: uppercase;
		font-weight: 700;
	}
	&-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.25rem 0.75rem 0.75rem 0.75rem;
		position: relative;
		z-index: 6;
		.a-btn-close.cart-close {
			margin-right: 0;
			margin-left: 0.5rem;
		}
		&::before {
			content: "";
			position: absolute;
			display: block;
			width: 100%;
			height: 1px;
			left: 0;
			top: calc(98% - 2px);
			background-color: rgba(225, 225, 225, 0.2);
			opacity: 0;
		}
		&::after {
			content: "";
			position: absolute;
			display: block;
			width: 100%;
			height: 2.75rem;
			left: 0;
			top: 98%;
			pointer-events: none;
			background: rgb(17, 17, 17);
			background: linear-gradient(
				180deg,
				rgba(17, 17, 17, 1) 0%,
				rgba(17, 17, 17, 0.72) 50%,
				rgba(17, 17, 17, 0) 100%
			);
		}
		.a-section-title {
			padding-bottom: 0;
		}
	}
	&-item {
		padding: 0 0.75rem;
		position: relative;
		.delete-cart-item {
			display: flex;
			position: absolute;
			right: 0.75rem;
			top: 1.75rem;
			transform: rotate(0);
			cursor: pointer;
			width: 21px;
			height: 21px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f2f2f2;
			transition: 0.35s;
			svg {
				path {
					transition: 0.35s;
				}
			}
			&:hover {
				transform: rotate(180deg);
				svg {
					path {
						fill: #eb5757;
					}
				}
			}
		}
		&__inner {
			padding: 1.25rem 0;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			display: flex;
			height: 100%;
		}
		&__img {
			width: 41%;
			height: auto;
			//padding-bottom: 28%;
			min-height: 80px;
			max-height: 120px;
			border-radius: 8px;
			//position: relative;
			overflow: hidden;
			.img {
				position: relative;
				width: 100%;
				height: 100%;

				img {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		&__desc {
			width: 59%;
			padding-left: 0.5rem;
			padding-right: 1.75rem;
			display: flex;
			flex-direction: column;
			height: auto;
		}
		&__title {
			font-weight: 700;
			margin-bottom: 0.375rem;
		}
		.weight {
			font-size: 0.875rem;
			color: #bdbdbd;
			margin-bottom: 0.375rem;
		}
		.price {
			font-size: 1.125rem;
			font-weight: 700;
			margin-bottom: 0.375rem;
			.price-sale {
				color: #eb5757;
			}
			.price-old {
				font-size: 0.875rem;
				position: relative;
				font-weight: 400;
				color: rgba(242, 242, 242, 0.41);
				&::after {
					content: "";
					width: 100%;
					display: block;
					height: 1px;
					left: 0;
					top: 50%;
					position: absolute;
					background-color: rgba(242, 242, 242, 0.41);
				}
			}
		}
	}
	&-bottom {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-bottom: 2.5rem;
		padding-top: 0.75rem;
		background-color: $black-light;
		z-index: 7;
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 1.125rem;
			margin-bottom: 1.125rem;
			font-size: 1.125rem;
			.label {
				font-weight: 500;
				padding-right: 1rem;
			}
			.value {
				white-space: nowrap;
			}
			&.total {
				padding-top: 1.25rem;
				margin-bottom: 1.5rem;
				border-top: 1px solid rgba(255, 255, 255, 0.2);
				font-size: 1.25rem;
			}
		}
		&__fixed {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			transition: 0.3s;
			transform: translateY(105%);
			padding: 0.75rem;
			background-color: $black;
			display: flex;
			align-items: center;
			z-index: 6;
			&::before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 99%;
				height: 2.75rem;
				width: 100%;
				z-index: 6;
				display: block;
				pointer-events: none;
				background: rgb(17, 17, 17);
				background: linear-gradient(
					0deg,
					rgba(17, 17, 17, 1) 0%,
					rgba(17, 17, 17, 0.72) 50%,
					rgba(17, 17, 17, 0) 100%
				);
			}
			&.fixed {
				opacity: 1;
				transform: translateY(0);
			}
			.value {
				padding-right: 2.25rem;
				font-size: 1.25rem;
				font-weight: 700;
			}
			.a-btn {
				flex: 1;
			}
		}
	}
	@include mq("md") {
		&-content {
			padding-top: 0.5rem;
		}
		&-top {
			padding: 1.5rem 4.5rem 1rem 4.5rem;
		}
		&-item {
			padding: 0 4.5rem;
			position: relative;
			.delete-cart-item {
				position: absolute;
				left: 2.25rem;
				top: 50%;
				transform: translate(-50%, -50%) rotate(0);
				cursor: pointer;
				width: 28px;
				height: 28px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #f2f2f2;
				transition: 0.35s;
				svg {
					path {
						transition: 0.35s;
					}
				}
				&:hover {
					transform: translate(-50%, -50%) rotate(180deg);
					svg {
						path {
							fill: #eb5757;
						}
					}
				}
			}
			&__inner {
				padding: 1.5rem 0;
			}
			&__img {
				width: 36%;
			}
			&__desc {
				width: 64%;
				padding-left: 1rem;
				padding-right: 0;
			}
		}
		&-bottom {
			padding-left: 4.5rem;
			padding-right: 4.5rem;
			&__fixed {
				padding: 1rem 4.5rem 1rem 1.5rem;
			}
		}
	}
}
