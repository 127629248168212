.a-logo {
	width: 100%;
	max-width: 136px;
	height: 100%;
	transition: 0.3s;
	.logo {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		& > * {
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			position: absolute;
			display: block;
			object-position: center;
			object-fit: contain;
		}
	}
}
