.m-actions {
	&-img {
		margin-bottom: 1rem;
		img {
			border-radius: 24px;
		}
	}
	&-content {
		max-width: 860px;
		font-size: 0.875rem;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.7);
		padding-bottom: 1.25rem;
		h3 {
			font-size: 1.25rem;
			margin-bottom: 0.625rem;
			color: white;
		}
		ul {
			list-style: disc;
			padding-left: 1rem;
			margin-bottom: 0.5rem;
			li {
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}
		}
		ol {
			padding-left: 0;
			li {
				font-size: 0.875rem;
				color: rgba(255, 255, 255, 0.7);
				margin-bottom: 0;
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}
		}
		p {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
		}
	}
	&-item {
		border-bottom: 1px solid #ffffff;
		.a-btn-arr {
			border: none;
			outline: none;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: white;
			transition: 0.3s;
			padding-left: 0;
			padding-right: 0;
			width: 100%;
			padding-top: 1rem;
			padding-bottom: 1rem;
			font-size: 1rem;
			text-transform: uppercase;
			text-align: end;
			svg {
				filter: grayscale(1);
				transition: 0.3s;
				flex-shrink: 0;
				width: 36px;
				height: auto;
				margin-right: 2rem;
			}
			&.active {
				color: #bf922d;

				svg {
					filter: grayscale(0);
				}
				& ~ .m-actions-content {
					height: auto;
					padding-bottom: 1.5rem;
					opacity: 1;
				}
			}
			&:hover {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
			}
			& ~ .m-actions-content {
				height: 0;
				overflow: hidden;
				padding-bottom: 0;
				opacity: 0;
				transition: 0.4s;
			}
		}
	}
	@include mq("sm") {
		&-img {
			margin-bottom: 1.25rem;
		}
		&-content {
			font-size: 1rem;
			h3 {
				font-size: 1.375rem;
				margin-bottom: 0.75rem;
			}
			ul {
				margin-bottom: 0.5rem;
				li {
					font-size: 1rem;
					padding-top: 0.375rem;
					padding-bottom: 0.375rem;
				}
			}
			ol {
				li {
					font-size: 1rem;
					padding-top: 0.375rem;
					padding-bottom: 0.375rem;
				}
			}
			p {
				padding-top: 0.375rem;
				padding-bottom: 0.375rem;
			}
		}
	}
	@include mq("md") {
		position: relative;
		&-list {
			width: 35%;
			padding-right: 0.5rem;
			padding-top: 1rem;
		}
		&-content {
			padding-left: 1.25rem;
			padding-top: 1.25rem;
		}
		&-item {
			border-bottom: none;
			.a-btn-arr {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
				text-align: start;
				font-size: 0.875rem;
				justify-content: flex-start;
				svg {
					margin-right: 0.5rem;
				}
				&.active {
					& ~ .m-actions-content {
						height: auto;
						padding-bottom: 1.5rem;
						opacity: 1;
					}
				}
				&:hover {
					padding-left: 0;
					padding-right: 0;
					opacity: 0.7;
				}
				& ~ .m-actions-content {
					position: absolute;
					left: 35%;
					width: 65%;
					top: 0;
					height: 0;
					overflow: hidden;
					padding-bottom: 0;
					opacity: 0;
					transition: 0.4s;
					border-left: 1px solid rgba(225, 225, 225, 0.3);
				}
			}
		}
	}
	@include mq("lg") {
		&-img {
			margin-bottom: 1.375rem;
		}
		&-list {
			padding-top: 1.25rem;
		}
		&-item {
			.a-btn-arr {
				padding-top: 0.625rem;
				padding-bottom: 0.625rem;
				svg {
					width: 40px;
					margin-right: 0.625rem;
				}
			}
		}
		&-content {
			padding-left: 1.625rem;
			padding-top: 1.625rem;
			h3 {
				font-size: 1.5rem;
				margin-bottom: 0.875rem;
			}
		}
	}
	@include mq("xl") {
		&-list {
			padding-top: 1.325rem;
		}
		&-item {
			.a-btn-arr {
				padding-top: 0.75rem;
				padding-bottom: 0.75rem;
				font-size: 1rem;
			}
		}
		&-content {
			max-width: 880px;
			padding-left: 1.75rem;
			padding-top: 1.75rem;
			h3 {
				font-size: 1.625rem;
				margin-bottom: 1rem;
			}
		}
	}
	@include mq("xxl") {
		&-list {
			padding-top: 1.5rem;
		}
		&-item {
			.a-btn-arr {
				font-size: 1.125rem;
				svg {
					width: 42px;
					margin-right: 0.75rem;
				}
			}
		}
		&-content {
			padding-left: 2rem;
			padding-top: 2rem;
			h3 {
				font-size: 1.75rem;
				margin-bottom: 1.125rem;
			}
		}
	}
}
