.a-header {
	&-phone {
		&__title {
			display: none;
		}
		&__icon {
			width: 38px;
			height: 38px;
			position: relative;
			background: $gold;
			z-index: 0;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.625rem;
			svg {
				width: 100%;
				height: auto;
			}
			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
				margin: 1px; /* !importanté */
				border-radius: inherit; /* !importanté */
				background: $black;
			}
		}
		a {
			display: flex;
			align-items: center;
			span {
				margin-right: 0.75rem;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	@include mq("sm") {
		&-phone {
			&__icon {
				width: 42px;
				height: 42px;
			}
		}
	}

	@include mq("lg") {
		&-phone {
			&__title {
				display: block;
			}
		}
	}
	@include mq("xl") {
		&-phone {
			&__title {
				font-size: 1.125rem;
			}
			&__icon {
				width: 48px;
				height: 48px;
				padding: 0.75rem;
			}
		}
	}
}
