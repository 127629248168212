@import url('https://fonts.cdnfonts.com/css/circe');

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

@mixin input-placeholder {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}

$breakpoints: (
    'xs': 0px,
    's': 370px,
    'sm': 577px,
    'md': 767px,
    'lg': 991px,
    'xl': 1199px,
    'xxl': 1399px,
);

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == min {
            $width: $width + 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}
