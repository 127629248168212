.categories {
	&-list {
		border: 0.5px solid rgba(135, 135, 135, 0.2);
		box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.06);
		.item {
			display: flex;
			align-items: center;
			padding: 0.5rem 0.5rem;
			height: 62px;
			border-bottom: 0.5px solid rgba(135, 135, 135, 0.2);
			cursor: context-menu;
			&.title {
				pointer-events: none;
			}
			&:hover {
				background-color: rgba(255, 255, 255, 0.027);
			}
			&:active {
				opacity: 0.68;
			}
			&.active {
				background-color: rgba(241, 194, 63, 0.027);
			}
			.img {
				flex: 1;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: left;
				}
			}
			.inputs {
				input:nth-of-type(1) {
					display: block;
				}
				input {
					display: none;
				}
			}
			& > * {
				border-right: 0.5px solid rgba(135, 135, 135, 0.2);
				display: flex;
				align-items: center;
				height: 100%;
				padding: 0 0.75rem;
				&:last-child {
					width: fit-content;
					border-right: 0;
					flex: 2;
				}
				&:nth-last-child(2) {
					flex: 6;
				}
			}
			&.item-sub {
				.name {
					span {
						padding-left: 1.25rem;
						color: rgba(255, 255, 255, 0.76);
					}
				}
			}
		}
	}
}
.category {
	&-title {
		font-size: 1.75rem;
		margin-top: 0.75rem;
		margin-bottom: 1.5rem;
	}
	&-create {
		&__row {
			.item {
				width: 50%;
				margin-bottom: 2.5rem;
			}
		}
	}
}
button {
	padding: 0.5rem 0.75rem;
	margin-right: 0.75rem;
	background-color: transparent;
	border: none;
	font-size: 0.875rem;
	text-transform: uppercase;
	border-radius: 6px;
	transition: 0.3s;
	&.delete {
		color: rgba(255, 0, 0, 0.678);
		border: 1px solid rgba(255, 0, 0, 0.678);
		&:hover {
			background-color: rgba(255, 0, 0, 0.178);
		}
	}
	&.update {
		color: rgba(255, 187, 0, 0.678);
		border: 1px solid rgba(255, 187, 0, 0.678);
		&:hover {
			background-color: rgba(255, 187, 0, 0.178);
		}
	}
	&.create {
		margin-top: 1rem;
		color: rgba(94, 255, 0, 0.678);
		border: 1px solid rgba(94, 255, 0, 0.678);
		&:hover {
			background-color: rgba(94, 255, 0, 0.178);
		}
	}
	&.upload-file {
		color: rgba(22, 97, 235, 0.678);
		border: 1px solid rgba(22, 97, 235, 0.678);
		&:hover {
			background-color: rgba(22, 97, 235, 0.178);
		}
	}
	&:active {
		opacity: 0.6;
	}
}
