.button-add-address {
    padding: 0.5rem 0.75rem;
    background-color: transparent;
    border: none;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-radius: 6px;
    transition: 0.3s;
    margin-top: 1rem;
    color: rgba(94, 255, 0, 0.678);
    border: 1px solid rgba(94, 255, 0, 0.678);
    &:disabled {
        pointer-events: none;
        opacity: 0.45;
    }
    &:hover {
        background-color: rgba(94, 255, 0, 0.178);
    }

    &:active {
        opacity: 0.6;
    }
}
.button-icon {
    margin-right: 0;
    &.del {
        color: rgba(255, 0, 0, 0.678);
        border: 1px solid rgba(255, 0, 0, 0.678);
        margin-left: 0.75rem;
        flex: 1;
        margin-top: 1rem;
        &:hover {
            background-color: rgba(255, 0, 0, 0.178);
        }
    }
    &.add {
        color: rgba(37, 62, 204, 0.678);
        border: 1px solid rgba(37, 62, 204, 0.678);
        margin-left: 0.75rem;
        flex: 1;
        margin-top: 1rem;
        &:hover {
            background-color: rgba(37, 62, 204, 0.178);
        }
    }
    &:active {
        opacity: 0.6;
    }
    &:disabled {
        pointer-events: none;
        opacity: 0.45;
    }
}
.contacts-admin {
    .img {
        width: 100%;
        height: 0;
        padding-bottom: 60%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
        }
    }
}
