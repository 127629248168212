.a-slider {
    &-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1.125rem;
        padding-bottom: 1.125rem;
        .a-slider-prev,
        .a-slider-next {
            display: none;
            cursor: pointer;
            transition: 0.25s;
            height: 48px;
            height: 48px;
            &:active {
                transform: scale(0.96);
            }
            & > * {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            &.swiper-button-disabled {
                filter: brightness(0.5);
                pointer-events: none;
            }
            &:hover {
                filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.568));
            }
        }
    }
    &-pagination {
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background: #bdbdbd;
            border-radius: 50%;
            margin-right: 0.5rem;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background: $gold;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &.swiper-pagination-custom {
            margin-left: 2.5rem;
            margin-right: 2.5rem;
            width: fit-content;
            display: flex;
            align-items: center;
            font-size: 1.625rem;
            .center {
                height: 1px;
                width: 46px;
                background-color: white;
                display: block;
                margin-left: 1rem;
                margin-right: 1rem;
            }
            .total {
                color: rgba(177, 177, 177, 1);
                font-size: 1.125rem;
            }
        }
    }
    @include mq('lg') {
        &-nav {
            .a-slider-prev,
            .a-slider-next {
                display: block;
            }
        }
    }
    @include mq('xl') {
        &-nav {
            .a-slider-prev,
            .a-slider-next {
                width: 54px;
                height: 54px;
            }
        }
    }
    @include mq('xxl') {
        &-nav {
            .a-slider-prev,
            .a-slider-next {
                width: 58px;
                height: 58px;
            }
        }
    }
}
