.a-burger {
	margin-right: 0.75rem;
	width: 38px;
	height: 38px;
	position: relative;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.625rem;
	background: $gold;
	z-index: 121;
	&.active {
		.a-burger-inner {
			span {
				opacity: 0;
			}
			&::after {
				transform: translate(-50%, -50%) rotate(45deg);
				top: 50%;
			}
			&::before {
				bottom: 50%;
				transform: translate(-50%, 50%) rotate(-45deg);
			}
		}
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		margin: 1px; /* !importanté */
		border-radius: inherit; /* !importanté */
		background-color: $black;
	}
	&-menu {
		display: none;
		text-transform: uppercase;
		margin-left: 0.75rem;
		line-height: 1;
	}
	&-inner {
		position: relative;
		width: 1.5rem;
		height: 1.5rem;

		span {
			transition: 0.3s;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: block;
			width: 18px;
			height: 1px;
			background: $gold;
		}
		&::after {
			transition: 0.3s;
			content: "";
			display: block;
			width: 18px;
			height: 1px;
			background: $gold;
			position: absolute;
			left: 50%;
			top: 25%;
			transform: translate(-50%, -50%);
		}
		&::before {
			transition: 0.3s;
			content: "";
			display: block;
			width: 18px;
			height: 1px;
			background: $gold;
			position: absolute;
			left: 50%;
			bottom: 25%;
			transform: translate(-50%, 50%);
		}
	}
	@include mq("sm") {
		width: 44px;
		height: 44px;
	}
	@include mq("md") {
		width: auto;
		border-radius: 50px;
		height: 46px;
		padding: 0.625rem 1.25rem;
		margin-right: 1rem;
		&-menu {
			display: block;
		}
	}
	@include mq("lg") {
		padding: 0.625rem 1.5rem;
		&-menu {
			margin-left: 1.125rem;
		}
	}
	@include mq("xl") {
		padding: 0.625rem 1.875rem;
		margin-right: 1.25rem;
		&-menu {
			margin-left: 1.25rem;
		}
	}
	@include mq("xl") {
		padding: 0.625rem 2.125rem;
		height: 52px;
	}
}
