.o-subscription {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background: $black-light;
	@include mq("sm") {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
	@include mq("md") {
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;
	}
	@include mq("lg") {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
	@include mq("xl") {
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
	}
	@include mq("xl") {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}
