@import 'core/module';
@import 'components/module';

body {
    font-weight: 400;
    font-family: 'Circe', sans-serif;
    font-size: 1rem;
    line-height: 1.3;
    color: $white;
    background-color: $black;
    padding-top: 86px;
    padding-bottom: 60px;

    margin: 0;

    //transition: 0.3s;
    //-webkit-box-shadow: 5px 5px 15px 5px #000000;
    //box-shadow: 5px 5px 15px 5px #000000;
    &.admin-page {
        padding-top: 0;
        padding-bottom: 0;
        //-webkit-box-shadow: none;
        //box-shadow: none;
    }
    &.scroll {
        padding-top: 74px;
        &.admin-page {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    &.hidden {
        overflow: hidden;
        //margin-right: 17px;
        //position: relative;
        //touch-action: none;
        //-ms-touch-action: none;
    }
    &.franchise {
        background-color: white;
        padding-bottom: 0;
    }
    button {
        font-family: 'Circe', sans-serif;
        cursor: pointer;
    }
    // &::-webkit-scrollbar {
    //     width: .5em;
    // }
    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // }
    // &::-webkit-scrollbar-thumb {
    //     background-color: #4F4D4D;
    //     border-radius: 10px;
    // }
}
.sceleton-card {
    svg {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    .sceleton-desc {
        display: none;
    }
}
.sceleton-title {
    svg {
        max-width: 80%;
        height: auto;
    }
}
.login-error {
    margin-top: 1rem;
    text-align: center;
    color: #f84e4e;
}
.button-file-img {
    display: flex;
    align-items: center;
    background-color: #e2e0e0da;
    justify-content: center;
    border: 0;
    border-radius: 8px;
    height: 48px;
    width: 48px;
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 85;
    transition: 0.3s;
    opacity: 0.85;
    &:active {
        transform: scale(0.96);
    }
    &:hover {
        opacity: 1;
    }
    &.delete-file {
        left: 4.75rem;
        svg {
            fill: rgba(255, 0, 0, 0.596);
        }
    }
    svg {
        fill: rgb(109, 108, 108);
        width: 32px;
        height: 32px;
    }
}
.main {
    overflow: hidden;

    &.delivery,
    &.about {
        .m-breadcrumbs {
            background-color: #1c1c1c;
        }
    }
}
ol {
    list-style: none;
    counter-reset: li;
    padding-left: 1rem;
    ol {
        padding-left: 0;
        margin-left: -1rem;
        margin-top: 0.75rem;
        li {
            line-height: 1.5;
            font-size: 0.875rem;
            color: $gray;
            font-weight: 350;
            &::before {
                font-weight: 500;
            }
        }
    }
    ul {
        list-style: disc;
        padding-left: 1rem;
        margin-top: 0.75rem;
        li {
            margin-bottom: 0;
            &::before {
                display: none;
            }
        }
    }
    li {
        font-size: 1.125rem;
        color: $blue;
        margin-bottom: 0.75rem;
        &::before {
            counter-increment: li;
            content: counters(li, '.') '. ';
        }
    }
}
.admin-textarea {
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: inherit;
    width: inherit;
    max-width: 100%;
    width: 100%;
    font-weight: inherit;
    color: inherit;
    resize: none;
}
.admin-input {
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: inherit;
    width: inherit;
    max-width: 100%;
    width: 100%;
    font-weight: inherit;
    color: inherit;
}
.w100 {
    max-width: 100% !important;
    width: 100% !important;
}
.container {
    max-width: 1480px;
    margin: auto;
    position: relative;
}
.container-sm {
    max-width: 1248px;

    margin: auto;
}
.line-bg {
    height: 1px;
    background-color: rgba(225, 225, 225, 0.3);
    width: 100%;
}
img {
    display: block;
    max-width: 100%;
}
a {
    text-decoration: none;
}
.color-blue {
    color: $blue;
}
.color-red {
    color: $red;
}
.upper {
    text-transform: uppercase;
}
.m-cart-item {
    .quantity {
        border: 1px solid rgba(225, 225, 225, 0.17);
        height: 36px;
        margin-top: auto;
    }
}
.m-product-card {
    .quantity {
        background: $gold;
        color: white;
        button {
            color: rgba(235, 235, 235, 0.81);
        }
    }
}
.quantity {
    display: flex;
    align-items: center;
    border-radius: 8e7px;
    width: fit-content;
    button {
        padding: 0.125rem;
        background-color: transparent;
        font-size: 1.25rem;
        border: none;
        height: 100%;
        width: 34px;
        font-weight: 400;
        color: rgba(235, 235, 235, 0.39);
        cursor: pointer;
    }
    input {
        text-align: center;
        font-family: 'Circe', sans-serif;
        color: white;
        border: none;
        outline: none;
        width: 40px;
        background-color: transparent;
        font-size: 1.125rem;
    }
    &.e--border {
        border: 1px solid rgba(225, 225, 225, 0.17);
    }
}
.show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem;
    &-btn {
        color: $gray;
        font-size: 0.875rem;
        font-weight: 350;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.25s;
        .hide {
            display: none;
        }
        &:hover {
            color: $blue;
            svg {
                path {
                    stroke: $blue;
                }
            }
        }
        svg {
            transition: 0.25s;
            margin-left: 0.5rem;
            width: 1rem;
            height: 1rem;
            path {
                transition: 0.25s;
            }
        }
    }
    &.active {
        .show-more-btn {
            svg {
                transform: rotate(180deg);
            }
            .hide {
                display: block;
            }
            .show {
                display: none;
            }
        }
    }
}
.input-submit {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    width: auto;
    padding: 0.5rem 0;
    input {
        border: none;
        background-color: transparent;
        border-radius: 0;
        outline: none;
        margin-right: 0.75rem;
        font-weight: 400;
        font-family: 'Circe', sans-serif;
        color: white;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 100%;
        &::placeholder {
            color: $gray-light;
        }
    }
    a {
        flex-shrink: 0;
        height: 2rem;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 100%;
        }
    }
}
.input {
    label {
        font-weight: 500;
        color: #686868;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        display: block;
        position: relative;
        border-bottom: 1px solid #ffffff;
        margin-top: 1.25rem;
        .label {
            display: block;
            position: absolute;
            left: 0;
            bottom: 50%;
            transform: translateY(50%);
            font-size: 1rem;
            line-height: 1.3;
            transition: 0.3s;
            pointer-events: none;
        }
        input {
            line-height: 1.3;
            height: 36px;
            width: 100%;
            border: none;
            box-sizing: border-box;
            border-radius: 0;
            background-color: transparent;
            outline: none;
            font-size: 1rem;
            color: white;
            padding: 0;
            &::placeholder {
                font-weight: 400;
                color: rgba(255, 255, 255, 0.5);
            }
            &:focus,
            &.filled {
                & + {
                    .label {
                        font-size: 0.75rem;
                        bottom: calc(100% - 3px);
                        transform: translateY(0);
                    }
                }
            }

            //&:valid {
            //	& + {
            //		.label {
            //			font-size: 0.75rem;
            //			bottom: calc(100% - 3px);
            //			transform: translateY(0);
            //		}
            //	}
            //}
        }
        .input-inner {
            input {
                margin-top: 0;
                padding-right: 2.5rem;
            }
        }
    }
    &-inner {
        margin-top: 0.75rem;
        position: relative;
        .password-control {
            position: absolute;
            right: 0.75rem;
            content: '';
            top: 50%;
            background: url(../assets/images/icons/view.svg) no-repeat;
            width: 24px;
            height: 24px;
            background-size: contain;
            transform: translateY(-50%);
            display: block;
            opacity: 0.66;
            border: none;
            background-color: transparent;
            &:hover {
                opacity: 1;
            }
            &.view {
                opacity: 1;
            }
        }
    }
}
.textarea {
    font-weight: 500;
    color: #686868;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    display: block;
    position: relative;
    border-bottom: 1px solid #ffffff;
    margin-top: 1.5rem;

    .label {
        display: block;
        position: absolute;
        left: 0;
        bottom: 50%;
        transform: translateY(50%);
        font-size: 1rem;
        line-height: 1.3;
        transition: 0.3s;
        pointer-events: none;
    }
    textarea {
        font-family: 'Circe', sans-serif;
        width: 100%;
        display: block;
        border: none;
        box-sizing: border-box;
        border-radius: 0;
        background-color: transparent;
        outline: none;
        font-size: 1rem;
        padding: 0rem;
        resize: none;
        height: 36px;
        color: white;

        &::placeholder {
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
        }
        &:-internal-autofill-selected {
            background-color: transparent !important;
        }
        &:focus,
        &.filled {
            height: fit-content;
            & + {
                .label {
                    font-size: 0.75rem;
                    bottom: calc(100%);
                    transform: translateY(0);
                }
            }
        }
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px #111 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.select {
    label {
        font-size: 0.875rem;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.534);
        height: auto;
        .select__inner {
            position: relative;
            &::after {
                content: '';
                background: url(../assets/images/icons/chevron-down-sharp.svg)
                    no-repeat;
                background-size: contain;
                width: 1rem;
                height: 1rem;
                position: absolute;
                right: 0rem;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
            }
            select {
                background-color: transparent;
                appearance: none;
                padding: 1rem;
                padding-right: 2rem;
                padding-left: 0;
                font-size: 0.875rem;
                width: 100%;
                border: 0;
                border-bottom: 1px solid white;
                border-radius: 2px;
                box-sizing: border-box;
                color: white;
                outline: none;
                width: 100%;
                option {
                    background-color: $black;
                }
            }
        }
    }

    &::placeholder {
        font-weight: 350;
        color: #878787;
    }
}
.radio-btn {
    label {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        &:hover {
            input {
                & + span {
                    border: 1px solid goldenrod;
                }
            }
        }
        input {
            width: 1px;
            height: 1px;
            transform: scale(0);
            position: absolute;
            left: -99999px;
            &:checked {
                & + span {
                    border: 1px solid goldenrod;
                    &::after {
                        opacity: 1;
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            }
            & + span {
                transition: 0.25s;
                display: block;
                width: 1.375rem;
                height: 1.375rem;
                border: 1px solid $gray;
                margin-right: 0.375rem;
                border-radius: 50%;
                position: relative;
                flex-shrink: 0;
                &::after {
                    position: absolute;
                    content: '';
                    width: 0.625rem;
                    height: 0.625rem;
                    display: block;
                    background-color: goldenrod;
                    transition: 0.25s;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    opacity: 0;
                }
            }
        }
    }
}
.overlay {
    position: fixed;
    width: 100%;
    height: 101vh;
    background: rgba(58, 58, 58, 0.5);

    top: 0;
    left: 0;
    opacity: 0;
    //transform: scale(0);
    transition: 0.3s;
    pointer-events: none;
    &.active {
        pointer-events: painted;
        opacity: 1;
        z-index: 109;
    }

    &.burger-active {
        z-index: 81;
        pointer-events: painted;
        opacity: 1;
    }
}
.modal-overlay {
    position: fixed;
    z-index: 100;
    inset: -1px;
    background: rgba(34, 34, 34, 0.7);
    animation: show-modal-overlay 0.3s;
    @keyframes show-modal-overlay {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.promocode {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 1.125rem;
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
    input {
        font-family: 'Circe';
        flex: 1;
        border: none;
        font-weight: 400;
        box-sizing: border-box;
        border-radius: 0;
        line-height: 1;
        outline: none;
        font-size: 1.125rem;
        padding: 0;
        padding-right: 1rem;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.8);
        min-width: 20px;
        &::placeholder {
            font-weight: 4000;
            color: rgba(255, 255, 255, 0.5);
        }
    }
    .promocode-link {
        font-size: 1.125rem;
        font-weight: 700;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        text-transform: capitalize;
        padding-left: 0;
        padding-right: 0;
        background: $gold;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        margin-right: 0;
        &:disabled {
            pointer-events: none;
            opacity: 0.35;
        }
    }
}
.checkbox {
    label {
        display: flex;
        align-items: center;
        color: #878787;
        font-size: 0.875rem;
        font-weight: 350;
        cursor: pointer;
        a {
            display: inline;
            text-decoration: underline;
            color: white;
            transition: 0.3s;
            &:hover {
                text-decoration: none;
                color: goldenrod;
            }
        }
        &:hover {
            span {
                border: 1px solid goldenrod;
            }
        }
        span {
            display: block;
            width: 22px;
            height: 22px;
            border: 1px solid $gray;
            position: relative;
            flex-shrink: 0;
            margin-right: 0.5rem;
            &::after {
                transition: 0.25s;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) scale(0);
                opacity: 0;
                background: $gold;
                background-size: contain;
                width: 12px;
                height: 12px;
                display: block;
                content: '';
            }
        }
        input[type='checkbox'] {
            position: absolute;
            width: 1px;
            height: 1px;
            display: block;
            left: -9999px;
            &:checked {
                & + span {
                    border: 1px solid goldenrod;
                    &::after {
                        opacity: 1;
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            }
        }
    }
}
@include mq('sm') {
    body {
        padding-top: 90px;
        &.scroll {
            padding-top: 76px;
        }
    }
    .sceleton-card {
        .sceleton-desc {
            display: block;
        }
        .sceleton-mob {
            display: none;
        }
    }
    ol {
        padding-left: 1.5rem;
        ol {
            margin-left: -1.5rem;
            margin-top: 1rem;
        }
        ul {
            padding-left: 1.25rem;
            margin-top: 1rem;
        }
        li {
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }
    }
}
@include mq('md') {
    body {
        padding-top: 96px;
    }
    ol {
        padding-left: 2rem;
        ol {
            margin-left: -2rem;
            margin-top: 1.125rem;
            li {
                font-size: 1rem;
            }
        }
        ul {
            padding-left: 1.5rem;
            margin-top: 1.125rem;
        }
        li {
            font-size: 1.5rem;
            margin-bottom: 1.125rem;
        }
    }

    .input-submit {
        input {
            font-size: 1rem;
        }
    }
    .container {
        width: 94%;
    }
    .container-sm {
        width: 92%;
    }
}
@include mq('lg') {
    body {
        padding-bottom: 0;
        padding-top: 148px;

        &.scroll {
            padding-top: 120px;
        }
        &.franchise {
            .container {
                padding-left: 0.75rem;
            }
        }
    }
    .container {
        width: 93%;
    }
    .container-sm {
        width: 88%;
    }

    .input-submit {
        input {
            font-size: 1.125rem;
        }
    }

    .select {
        label {
            font-size: 0.875rem;
            .select__inner {
                select {
                    font-size: 1rem;
                }
            }
        }

        &::placeholder {
            font-weight: 350;
            color: #878787;
        }
    }
    ol {
        padding-left: 3rem;
        ol {
            margin-left: -3rem;
            margin-top: 1.25rem;
            li {
                font-size: 1.125rem;
            }
        }
        ul {
            padding-left: 1.75rem;
            margin-top: 1.25rem;
        }
        li {
            font-size: 1.75rem;
            margin-bottom: 1.25rem;
        }
    }
    @include mq('xl') {
        body {
            padding-top: 152px;
            width: calc(100vw - 17px);
            &.scroll {
                padding-top: 122px;
            }
        }
        .container {
            width: 88%;
        }
        ol {
            padding-left: 4rem;
            ol {
                margin-left: -4rem;
                margin-top: 1.75rem;
                li {
                    font-size: 1.25rem;
                }
            }
            ul {
                padding-left: 2rem;
                margin-top: 1.5rem;
            }
            li {
                font-size: 2rem;
                margin-bottom: 1.5rem;
            }
        }
        .input {
            label {
                margin-top: 1.375rem;
                .label {
                    font-size: 1.125rem;
                }
                input {
                    height: 40px;

                    font-size: 1.125rem;
                }
            }
        }
        .textarea {
            .label {
                font-size: 1.125rem;
            }
            textarea {
                font-size: 1.125rem;
                height: 40px;
            }
        }
    }
    @media only screen and (min-width: 1601px) {
        body {
            padding-top: 160px;
            &.scroll {
                padding-top: 118px;
            }
        }
    }
}
