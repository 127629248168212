.m-hero {
	&-slide {
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56%;
			border-radius: 24px;
			overflow: hidden;
			a {
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
	@include mq("sm") {
		&-slide {
			&__inner {
				padding-bottom: 54%;
			}
		}
	}
	@include mq("md") {
		&-slide {
			&__inner {
				padding-bottom: 52%;
			}
		}
	}
	@include mq("lg") {
		&-slide {
			&__inner {
				padding-bottom: 44%;
			}
		}
	}
	@include mq("xl") {
		&-slide {
			&__inner {
				padding-bottom: 43%;
			}
		}
	}
	@include mq("xxl") {
		&-slide {
			&__inner {
				padding-bottom: 42%;
			}
		}
	}
	@media only screen and (min-width: 1602px) {
		&-slide {
			&__inner {
				padding-bottom: 45%;
			}
		}
	}
}
