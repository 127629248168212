.actions {
    &-list {
        border: 0.5px solid rgba(135, 135, 135, 0.2);
        box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.06);
        .item {
            display: flex;
            align-items: center;
            padding: 0.5rem 0.5rem;
            height: 100px;
            border-bottom: 0.5px solid rgba(135, 135, 135, 0.2);
            cursor: context-menu;
            &:first-child {
                height: 58px;
            }
            & > * {
                border-right: 0.5px solid rgba(135, 135, 135, 0.2);
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 0.75rem;
                &:first-child {
                    flex: 3;
                    img {
                        display: block;
                        max-width: 80%;
                        height: 82px;
                        object-fit: cover;
                        border-radius: 20px;
                    }
                }
                &:nth-child(2) {
                    flex: 7;
                }
                &:nth-child(3) {
                    flex: 4;
                }
                &:last-child {
                    border-right: 0;
                }
            }
        }
    }
    button,
    a.btn {
        display: block;
        width: fit-content;
        padding: 0.5rem 0.75rem;
        margin-right: 0.75rem;
        background-color: transparent;
        border: none;
        font-size: 0.875rem;
        text-transform: uppercase;
        border-radius: 6px;
        transition: 0.3s;
        &.delete {
            color: rgba(255, 0, 0, 0.678);
            border: 1px solid rgba(255, 0, 0, 0.678);
            &:hover {
                background-color: rgba(255, 0, 0, 0.178);
            }
        }
        &.update {
            color: rgba(255, 187, 0, 0.678);
            border: 1px solid rgba(255, 187, 0, 0.678);
            &:hover {
                background-color: rgba(255, 187, 0, 0.178);
            }
        }
        &.create {
            margin-top: 1rem;
            color: rgba(94, 255, 0, 0.678);
            border: 1px solid rgba(94, 255, 0, 0.678);
            &:hover {
                background-color: rgba(94, 255, 0, 0.178);
            }
        }
        &:active {
            opacity: 0.6;
        }
    }
}
