.a-btn {
    height: 38px;
    padding: 1px;
    color: white;
    border: 0;
    background: $gold;
    border-radius: 1e8px;
    text-transform: uppercase;
    font-size: 0.75rem;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
    &:active {
        transform: scale(0.98);
    }
    &:hover .a-btn-inner {
        background: $black;
    }
    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.375rem 0.5rem;
        height: 100%;
        background: $gold;
        border-radius: inherit;
    }

    svg {
        width: 17px;
        height: 17px;
        display: block;
        object-fit: contain;
        margin-right: 0.375rem;
    }
    &-close {
        width: 38px;
        height: 38px;
        background: $gold;
        outline: none;
        border-radius: 50%;
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        transition: 0.35s;
        transform: rotate(0);
        svg {
            path {
                transition: 0.45s;
            }
        }
        &:hover {
            transform: rotate(180deg);
        }
    }

    &.e--gold {
        background: $gold;
        padding: 0.25rem;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.update {
        background: $black;
        transition: 0.3s;
        background: $gold;
        padding: 2px;
        position: fixed;
        right: 2rem;
        top: 100px;
        z-index: 63;
        .a-btn-inner {
            padding: 0.5rem 1rem;
            background: $black;
            transition: 0.3s;
        }
        &:hover {
            .a-btn-inner {
                background: $black-light;
            }
        }
    }
    @include mq('sm') {
        background: rgba(225, 225, 225, 0.2);
        font-size: 0.875rem;
        &:hover {
            background: $gold;
            .a-btn-inner {
                background: $black-light;
            }
        }
        &-inner {
            background: $black-light;
        }
    }
    @include mq('lg') {
        font-size: 1rem;
        height: 46px;
    }
    @include mq('xxl') {
        font-size: 1.125rem;
        height: 52px;
    }
}
