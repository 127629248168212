.credit-cards {
    &__list {
        .item {
            &-inner {
                .img {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: 56%;
                    border-radius: 18px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        object-fit: cover;
                        object-position: center;
                    }
                    &::after {
                        transition: 0.3s;
                        background-color: rgba(0, 0, 0, 0.264);
                        opacity: 0;
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .delete-card {
                        transition: 0.4s;
                        margin: 0;
                        width: 30%;
                        height: 100%;
                        right: -100%;
                        position: absolute;
                        border-radius: 0;
                        display: block;
                        z-index: 2;
                        &:active {
                            opacity: 0.8;
                            &::after {
                                background-color: rgba(136, 77, 77, 0.675);
                            }
                        }
                        &::after {
                            transition: 0.3s;
                            background-color: rgba(187, 122, 122, 0.432);
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            content: '';
                            left: 0;
                            top: 0;
                            filter: blur(3px);
                            z-index: 2;
                        }
                        svg {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            z-index: 3;
                            transform: translate(-50%, -50%) rotate(45deg);
                            width: 50%;
                            height: auto;
                        }
                    }
                    &.add-img {
                        svg {
                            width: 33%;
                            position: absolute;
                            display: block;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            top: 50%;
                            height: auto;
                            color: rgb(7, 63, 7);
                        }
                    }
                }
                &:hover {
                    .delete-card {
                        right: 0;
                    }
                    .img {
                        &::after {
                            opacity: 1;
                        }
                    }
                    .number {
                        opacity: 0.7;
                    }
                }
                .number {
                    transition: 0.3s;
                    text-align: center;
                    margin-top: 0.5rem;
                    font-size: 1.125rem;
                    .save-btn {
                        padding: 0 0.375rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            svg {
                                color: rgb(75, 75, 184);
                            }
                        }
                        svg {
                            width: 24px;
                            height: auto;
                            color: rgb(47, 47, 255);
                            transition: 0.3s;
                        }
                    }
                    .admin-input {
                        text-align: center;
                    }
                }
                .add-banner.img {
                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 0;
                    &::after {
                        display: none;
                    }
                }
            }
            .delete-card.no-img {
                opacity: 0.5;
                transition: 0.3s;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
