.pages {
    &-list {
        display: flex;
        flex-wrap: wrap;
        li {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            width: 50%;
        }
        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            border-radius: 10px;
            width: 100%;
            border: 0.5px solid rgba(135, 135, 135, 0.2);
            box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.06);
            font-size: 1.5rem;
            opacity: 0.75;
            transition: 0.3s;
            margin-bottom: 1rem;
            &:active {
                transform: scale(0.9);
            }
            &:hover {
                opacity: 1;
            }
            span {
                display: flex;
                align-items: center;
            }
            svg {
                margin-right: 0.75rem;
                width: 40px;
                height: 40px;
                display: block;
                object-fit: contain;
            }
            &.about {
                svg {
                    path {
                        fill: goldenrod;
                    }
                }
            }
        }
    }

    //@media screen and (max-width: 1400px) {
    //    &-list {
    //        li {
    //            width: 33.3%;
    //        }
    //    }
    //}
    @media screen and (max-width: 992px) {
        &-list {
            li {
                width: 100%;
            }
        }
    }
    //@media screen and (max-width: 560px) {
    //    &-list {
    //        li {
    //            width: 100%;
    //        }
    //    }
    //}
}
