.m-toaster {
    position: fixed;
    left: 50%;
    top: 64px;
    z-index: 175;
    min-width: 224px;
    border-radius: 20px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    box-shadow: 1px 1px 10px 1px rgba(102, 102, 102, 0.3);
    cursor: context-menu;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
    &.active {
        opacity: 1;
        transform: translate(-50%, 0);
    }
    &-inner {
        justify-content: center;
        padding: 0.75rem 1rem;
        display: flex;
        align-items: center;
        background-color: rgb(36, 36, 36);
        .icon {
            margin-right: 0.5rem;
        }
    }
    @include mq('xl') {
        font-size: 1.25rem;
        &-inner {
            padding: 0.75rem 1.25rem;
            .icon {
                margin-right: 0.75rem;
            }
        }
    }
}
