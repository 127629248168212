.admin {
    padding-top: 72px;
    padding-left: 16%;
    &-container {
        width: 90%;
        max-width: 1496px;
        padding: 1rem;
        margin: auto;
    }
    @media screen and (max-width: 1200px) {
        &-container {
            width: 94%;
        }
    }
    @media screen and (max-width: 992px) {
        &-container {
            width: 100%;
        }
    }
}
