.m-cabinet {
    &-content {
        &__item {
            display: none;
            &.active {
                display: block;
            }
        }
    }
    &-personal {
        &__info {
            .input {
                margin-bottom: 1.25rem;
            }
            .info-block {
                border: 0.5px solid rgba(135, 135, 135, 0.2);
                box-sizing: border-box;
                box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.06);
                border-radius: 15px;
                padding: 0.875rem;
                .title {
                    font-size: 1.75rem;
                    color: white;
                    padding-bottom: 1.25rem;
                    border-bottom: 1px solid $gray-light;
                    margin-bottom: 2rem;
                }
                .info-item {
                    margin-bottom: 1.75rem;
                    .label {
                        font-size: 0.875rem;
                        color: $gray-light;
                        font-weight: 400;
                        margin-bottom: 1.5rem;
                    }
                    .value {
                        font-size: 1rem;
                        font-weight: 350;
                        color: rgba(255, 255, 255, 0.808);
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .edit-info {
                .title {
                    margin-bottom: 1.5rem;
                    font-size: 1.25rem;
                }
                .button {
                    .a-btn {
                        width: 100%;
                    }
                }
            }
            .cashback-block {
                background: #ffffff;
                border: 0.5px solid rgba(67, 142, 173, 0.2);
                box-sizing: border-box;
                box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.06);
                border-radius: 20px;
                padding: 1.25rem;
                .cashback {
                    display: flex;
                    align-items: center;
                    font-size: 1rem;
                    color: $blue;
                    svg {
                        margin-right: 0.375rem;
                    }
                    .count {
                        flex: 1;
                        text-align: end;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    &-tabs {
        display: flex;
        overflow: auto;
        margin-bottom: 1.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        li {
            margin-right: 1.5rem;
            &:last-child {
                margin-right: 0;
            }
            a {
                transition: 0.25s;
                white-space: nowrap;
                padding: 0.5rem;
                border-radius: 8px;
                font-size: 0.875rem;
                color: $gray;
                cursor: pointer;
                &:hover {
                    color: $blue;
                }
                &.active {
                    color: white;
                    background-color: $blue;
                }
            }
        }
    }
    @include mq('md') {
        &-personal {
            &__info {
                .edit-info {
                    .title {
                        font-size: 1.375rem;
                    }
                }
                .info-block {
                    padding: 1rem;
                }
            }
        }
        &-tabs {
            margin-bottom: 2rem;
            justify-content: center;
            li {
                padding: 0.625rem;
            }
        }
    }
    @include mq('lg') {
        &-tabs {
            margin-bottom: 2.5rem;
            li {
                font-size: 1rem;
            }
        }
    }
    @include mq('xl') {
        &-personal {
            &__info {
                .edit-info {
                    &-col {
                        &:nth-of-type(even) {
                            padding-left: 1.5rem;
                        }
                        &:nth-of-type(odd) {
                            padding-right: 1.5rem;
                        }
                    }
                }
                .cashback-block {
                    padding: 1.75rem;
                    .cashback {
                        font-size: 1.125rem;
                        svg {
                            margin-right: 0.5rem;
                        }
                    }
                }
                .info-block {
                    padding: 1.5rem;
                    margin-bottom: 2.5rem;
                }
            }
        }
    }
}
