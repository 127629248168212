.o-header {
    transition: 0.35s;
    position: fixed;
    top: 0;
    width: 100%;

    left: 0;
    z-index: 100;
    height: 86px;
    background-color: $black;
    border-bottom: 1px solid rgba(225, 225, 225, 0.25);
    &::after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(58, 58, 58, 0.5);
        position: absolute;
        opacity: 0;
        transition: 0s;
        pointer-events: none;
    }
    &.active::after {
        opacity: 1;
    }
    &.scroll {
        height: 64px;
    }
    .container {
        height: 100%;
    }
    @include mq('sm') {
        height: 90px;
    }
    @include mq('md') {
        height: 96px;
    }
    @include mq('lg') {
        height: 100px;
        &.scroll {
            height: 68px;
        }
    }
    @include mq('xl') {
        height: 104px;
        width: calc(100vw - 17px);
    }
    @media only screen and (min-width: 1601px) {
        height: 108px;
    }
}
