.m-payment {
    &-card {
        .card-number {
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;
            left: 9999px;
        }
        &__inner {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 54%;
            border-radius: 20px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
            }
        }
    }
    &-slider {
        padding: 1.5rem 2.25rem 2.25rem 2.25rem;
        overflow: hidden;
        position: relative;
        .a-slider-pagination {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0.5rem;
            .swiper-pagination-bullet {
                transition: 0.3s;
                width: 8px;
                height: 8px;
                border-radius: 1e8px;
                &.swiper-pagination-bullet-active {
                    width: 16px;
                    border-radius: 1e8px;
                }
            }
        }
    }

    @include mq('md') {
        padding-top: 0;
        border-top: 0;
        padding-bottom: 2rem;
        padding-right: 1rem;
        position: relative;
        z-index: 1;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 99%;
            height: 2.25rem;
            width: 100%;
            display: block;
            pointer-events: none;
            background: rgb(17, 17, 17);
            background: linear-gradient(
                0deg,
                rgba(17, 17, 17, 1) 0%,
                rgba(17, 17, 17, 0.72) 50%,
                rgba(17, 17, 17, 0) 100%
            );
        }
        &-slider {
            padding-left: 5rem;
            padding-right: 5rem;
        }
    }
    @include mq('lg') {
        padding-right: 1.5rem;
    }
    @include mq('xl') {
        padding-right: 2rem;
    }
}
