.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 16%;
    border-right: 1px solid gray;
    bottom: 0;
    z-index: 20;
    background-color: #111;
    .top {
        height: 72px;
        .logo {
            height: 100%;
            padding: 0.625rem 0.75rem;
            display: flex;
            align-items: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: left;
            }
        }
    }
    .center {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        ul {
            li {
                a {
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    padding: 0.875rem;
                    font-size: 1rem;
                    transition: 0.3s;
                    background-color: rgba(207, 207, 207, 0);
                    opacity: 0.35;
                    &:hover {
                        background-color: rgba(207, 207, 207, 0.1);
                        opacity: 0.6;
                    }
                    svg {
                        margin-right: 0.5rem;
                        width: 22px;
                        height: 22px;
                        display: block;
                        object-fit: contain;
                        fill: rgba(218, 165, 32, 0.781);
                    }
                    &:active {
                        background-color: rgba(207, 207, 207, 0.03);
                        opacity: 0.6;
                    }
                    &.active {
                        opacity: 0.85;
                        & ~ ul {
                            display: block;
                        }
                    }
                }
                ul {
                    display: none;

                    li {
                        a {
                            font-size: 0.875rem;
                            padding: 0.5rem 1.625rem;
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 992px) {
        .center {
            ul {
                li {
                    a {
                        justify-content: center;
                        svg {
                            width: 46px;
                            height: 46px;
                        }
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
