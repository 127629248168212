.m-tabs {
    overflow: auto;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    &-list {
        margin: auto;
        width: fit-content;
        padding: 0.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(225, 225, 225, 0.17);
        border-radius: 8e7px;
        li {
            color: white;
            font-weight: 400;
            white-space: nowrap;
            margin-right: 0.375rem;
            position: relative;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            button {
                font-size: 0.875rem;
                display: block;
                transition: 0.3s;
                outline: none;
                border: none;
                background: $black;
                color: white;
                padding: 0.5rem 1rem;
                border-radius: 8e7px;
                cursor: pointer;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    margin: -1px; /* !importanté */
                    border-radius: inherit; /* !importanté */
                    background: $gold;
                    opacity: 0;
                    transform: 0.3s;
                }
                &:hover {
                    &:before {
                        opacity: 1;
                    }
                }
            }

            a {
                &.active {
                    button {
                        background: $gold;
                    }
                }
            }
        }
    }
    @include mq('md') {
        &-list {
            li {
                button {
                    font-size: 1rem;
                }
            }
        }
    }
    @include mq('lg') {
        &-list {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 0;
            li {
                margin-right: 2rem;
                button {
                    padding: 0.5rem 0;
                    border-radius: 0;
                    &:before {
                        content: '';
                        position: absolute;
                        top: auto;
                        right: auto;
                        bottom: 0;
                        left: 0;
                        z-index: 1;
                        margin: 0; /* !importanté */
                        width: 100%;
                        height: 1px;
                        background: white;
                        opacity: 0;
                        transform: 0.3s;
                    }
                    &:hover {
                        &:before {
                            opacity: 1;
                        }
                    }
                }

                a {
                    &.active {
                        button {
                            background: $gold;
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                            -webkit-background-clip: text;
                            &::before {
                                background: $gold;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    @include mq('xl') {
        &-list {
            li {
                button {
                    font-size: 1.125rem;
                }
            }
        }
    }
}
