.m-ordering {
    &-item {
        padding: 1.25rem 1rem;
        border: 0.5px solid rgba(135, 135, 135, 0.2);
        box-sizing: border-box;
        box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.06);
        border-radius: 15px;
        margin-bottom: 1.5rem;

        &__title {
            transition: 0.25s;
            font-size: 1.125rem;
            font-weight: 700;
            color: white;
            text-transform: uppercase;
            padding-bottom: 1.25rem;
        }
    }
    &-left {
        max-width: 480px;
        margin: auto;
    }
    &-right {
        max-width: 480px;
        margin: auto;
        .m-ordering-item__title {
            display: none;
            position: relative;
            z-index: 1;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 99%;
                height: 2.25rem;
                width: 100%;
                display: block;
                pointer-events: none;
                background: rgb(17, 17, 17);
                background: linear-gradient(
                    180deg,
                    rgba(17, 17, 17, 1) 0%,
                    rgba(17, 17, 17, 0.72) 50%,
                    rgba(17, 17, 17, 0) 100%
                );
            }
        }
        .m-payment {
            display: none;
            padding-top: 0;
            border-top: 0;
            &-top {
                padding: 0;
                background-color: transparent;
                border: 0;
                box-shadow: none;
            }
            .a-btn {
                opacity: 0.85;
                &:hover {
                    opacity: 1;
                }
                &:active {
                    opacity: 0.7;
                }
            }
        }
    }

    @include mq('sm') {
        &-item {
            &__title {
                font-size: 1.25rem;
            }
        }
        &-cart {
            &__item {
                .img {
                    padding-right: 0.375rem;
                    grid-column: span 3;
                    grid-row-start: 1;
                    grid-row-end: 3;
                    .img-inner {
                        padding-bottom: 81.8%;
                    }
                }
                .title {
                    grid-column: span 4;
                    font-size: 0.875rem;
                    color: $black;
                }
                .line {
                    display: none;
                }
                .a-product-card__price {
                    grid-column-start: 10;
                    grid-column-end: 13;
                    grid-row-start: 1;
                    grid-row-end: 3;
                    text-align: end;
                    height: 100%;
                    padding-right: 0.75rem;
                    border-right: 1px solid $gray-light;
                    .price {
                        height: 100%;
                        justify-content: center;
                    }
                }
                .weight {
                    grid-row-start: 2;
                    grid-row-end: 3;
                    grid-column-start: 4;
                    grid-column-end: 11;
                    justify-self: start;
                    font-size: 0.875rem;
                    color: #828282;
                }
                .m-ordering-cart__count {
                    grid-column-start: 9;
                    grid-column-end: 10;
                    font-size: 0.875rem;
                    color: $gray;
                    grid-row-start: 1;
                    grid-row-end: 3;
                }
            }
        }
    }
    @include mq('md') {
        &-cart {
            &__list {
                padding-right: 1rem;
                overflow: auto;
                max-height: 460px;
                &::-webkit-scrollbar {
                    width: 0.375em;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba(135, 135, 135, 0.2);
                }
                &::-webkit-scrollbar-thumb {
                    background: $gold;
                    border-radius: 10px;
                }
            }
        }
        &-item {
            padding: 0;
            border: none;
            box-shadow: none;
            border-radius: 0;
            margin-bottom: 1.5rem;
            &__title {
                font-size: 1.375rem;
            }
        }
        &-left {
            margin: 0;
            max-width: none;
            padding-right: 2rem;
        }
        &-right {
            margin: 0;
            max-width: none;
            .m-ordering-item__title {
                display: block;
            }
            &__inner {
                position: sticky;
                top: 0rem;
            }
            .m-payment {
                display: block;
            }
        }
    }
    @include mq('lg') {
        &-item {
            &__title {
                font-weight: 400;
                font-size: 1.5rem;
            }
        }
        &-left {
            padding-right: 2.5rem;
        }
        &-cart {
            &__list {
                padding-right: 1.5rem;
                overflow: auto;
                max-height: 440px;
            }
            &__item {
                padding: 1rem;
                .title {
                    font-size: 0.875rem;
                }
            }
        }
    }
    @include mq('xl') {
        &-item {
            &__title {
                font-size: 1.625rem;
            }
        }
        &-left {
            padding-right: 3.5rem;
        }
        &-cart {
            &__list {
                padding-right: 2rem;
                overflow: auto;
                max-height: 500px;
            }
            &__item {
                padding: 1.25rem;
                .title {
                    font-size: 1rem;
                }
            }
        }
    }
}
